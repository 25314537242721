@tailwind base;
@tailwind components;
@tailwind utilities;

.interactive_shown {
  transition: opacity 1s ease-in;
  opacity: 1;
  height: auto;
}

.interactive_hidden {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}